import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Components
import Container from "components/container"
import WebinarBody from "components/webinar/body"

// Downloads
import PresentationSlides from "assets/downloads/sexual-health-test/webinar-replay/2022_08_17-Disentangling_Common_Vaginal_Infections.pdf"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  padding: 0px;
`

const BodyLanding = props => {

	return (
	<StyledContainer>
    	<WebinarBody
			viewAllowed={props.viewAllowed}
    	>
    	<div className="flexBox">
			<div className="block block--left">
				  <div className="block__content">
					<h2 data-aos="fade-up">Overview</h2>
					<p data-aos="fade-up">
					Trichomoniasis, the most common non-viral sexually transmitted infection, is underdiagnosed due to lack of screening, 
					surveillance, and control programs. The most commonly used diagnostic test lacks sensitivity. Undertreating 
					trichomoniasis can lead to adverse health outcomes and persistence of infection in sexual networks. This webinar 
					evaluates a modern approach to managing this neglected disease.
					</p>
					<h2 data-aos="fade-up">Learning Objectives</h2>
					<p data-aos="fade-up" className="subtitle">In this video, participants will:</p>
					<ul data-aos="fade-up">
						<li>Distinguish between BV and trichomoniasis in terms of epidemiology, pathogenesis, and adverse health outcomes</li>
						<li>Recognize clinical symptoms associated with both vaginal infections</li>
						<li>Discuss traditional and novel diagnostic methods for BV and trichomoniasis</li>
						<li>Illustrate how point-of-care testing could accurately and rapidly diagnose trichomoniasis</li> 
					</ul>
				  </div>
				</div>
				{ props.featured===true ? 
					null
					: (
			   <div className="block block--right">
				   <div className={ (props.pageName==='display' | props.viewAllowed===false ) ? "accessBox show" : "accessBox" }>
						<h3 data-aos="fade-up">Access Includes:</h3>
						<ul data-aos="fade-up">
							<li>Video recording replay</li>
							<li>Presentation Slides (PDF)</li>
						</ul>
					</div>
				   <div className={ (props.pageName==='download') ? "downloadBox show showPDF" : "downloadBox" }>
						<h3 data-aos="fade-up">Download</h3>
						<p data-aos="fade-up"><a href={PresentationSlides} target="_blank" rel="noopener noreferrer">Presentation Slides (PDF)</a></p>
					</div>
				</div>
					)
					}
			</div>

			<div className="block block--left">
			  <div className="block__content presenter">
				<h2  data-aos="fade-up">About the Presenter</h2>
				
				<div className="presenterBio" data-aos="fade-up">
				<StaticImage
					src="../../../../assets/images/webinar-replay/christina-munzy.jpg"
					width={200}
					alt="Dr. Christina Muzny"
					className="presenter__image"
				/>
				<p data-aos="fade-up">Dr. Christina Muzny is a tenured Associate Professor in the Division of Infectious Diseases at the 
				University of Alabama (UAB) with a secondary appointment in the Department of Epidemiology.
				She is a medical consultant for the 2021 CDC STI Treatment Guidelines, a teaching faculty for the 
				Alabama-North Carolina STD/HIV Prevention Training Center and an Associate Scientist for the UAB Center for AIDS Research. 
				Her clinical and research interests focus on the epidemiology, diagnosis, and treatment of HIV and sexually transmitted infections, 
				specifically vaginal infections. Muzny has R01 and R21 funding from NIH/NIAID to study the pathogenesis of BV and has 
				participated in clinical trials regarding use of 5-nitroimidazoles and secnidazole for the treatment of trichomoniasis.</p>
				</div>
			</div>
		</div>
    	</WebinarBody>
    </StyledContainer>
  )
}

class FullBody extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewAllowed: false
    }
  }

  componentDidMount = props=> {
  if(localStorage.getItem(this.props.localStorageID)) {
		this.setState({
				viewAllowed: true
			})
		}
	}

  render = () => (
    <BodyLanding
		pageName={this.props.pageName}
		viewAllowed={this.state.viewAllowed}
		localStorageID={this.props.localStorageID}
		featured={this.props.featured}
		featuredDate={this.props.featuredDate}
		featuredTime={this.props.featuredTime}
		featuredCost={this.props.featuredCost}
		featuredUrl={this.props.featuredUrl}
    />
  )
}

export default FullBody
