import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import WebinarVideo from "./../screens/webinar-replay/webinar-video"
import BodyLanding from "./../screens/webinar-replay/2022-08-17-muzny/body-landing"
import SeriousTargets from "./../screens/webinar-replay/serious-targets"

const MuznyView = () => {
	return (
		<React.Fragment>
			<WebinarVideo
				videoTitle="Disentangling Common Vaginal Infections with Rapid Point-of-Care Testing"
				videoDisplayTitle="Disentangling Common Vaginal Infections with Rapid Point-of-Care Testing"
				vimeoLink="https://player.vimeo.com/video/741158397?h=136fdf3b1a"
				placeholderImage="2022-08-17-muzny-webinar-replay.jpg"
				localStorageID="2022-08-17-muzny-webinar-replay"
				pageSlug="/webinar-replay/2022-08-17-muzny/"
			/>
			<BodyLanding pageName="download" localStorageID="2022-08-17-muzny-webinar-replay" />
			<SeriousTargets />
		</React.Fragment>
	)
}

export default MuznyView

export const Head = () => (
  <Seo
        title="Webinar Replay: Disentangling Common Vaginal Infections with Rapid Point-of-Care Testing"
        description="Presenter Dr. Christina Muzny talks about Disentangling Common Vaginal Infections with Rapid Point-of-Care Testing."
        image="/meta/webinar-muzny-2022-08-17.jpg"
  />
)
